// @mui/material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "../../../components/Card/RotatingCard";
import RotatingCardFront from "../../../components/Card/RotatingCard/RotatingCardFront";
import RotatingCardBack from "../../../components/Card/RotatingCard/RotatingCardBack";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "../../../assets/images/rotating-card-bg-front.jpeg";
import bgBack from "../../../assets/images/rotating-card-bg-back.jpeg";

// Mui Icons
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { fetchAuthSession } from "aws-amplify/auth";

function Information() {
  const jwkUser = useRef("");

  const encodeJWT = (jwt) => {
    return window.btoa(JSON.stringify(jwt));
  };

  async function currentAuthenticatedUser(jwkUser) {
    try {
      const { tokens: session } = await fetchAuthSession();
      console.log(`The session: ${JSON.stringify(session)}`);

      const accessToken = encodeJWT(session.accessToken.payload);
      jwkUser.current = accessToken;

      document.cookie = `authToken=${accessToken}; path=/; domain=.nuxway.services; Secure; SameSite=None;`;
    } catch (err) {
      console.error("Error al obtener el usuario autenticado:", err);
    }
  }

  useEffect(() => {
    currentAuthenticatedUser(jwkUser);
  }, []);

  useEffect(() => {
    const printMessage = () => {
      console.log("Han pasado 5 minutos");
    };
    const timer = setInterval(() => {
      printMessage();
    }, 300000);

    return () => clearInterval(timer);
  }, []);

  return (
    <MKBox component="section" py={6} my={3}>
      <Container>
        <Grid
          container
          item
          xs={11}
          spacing={3}
          alignItems="center"
          sx={{ mx: "auto" }}
        >
          {/* Primer Item */}
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard locked={false}>
              <RotatingCardFront
                image={bgFront}
                icon={<ContactPhoneIcon />}
                title={<>NuxCaller</>}
                description="Automated interactive voice call service for reminders or advertising."
              />
              <RotatingCardBack
                image={bgBack}
                title="Make automatic Calls!"
                description="Select an IVR with which you will call all your clients!"
                action={{
                  type: "external",
                  route: `https://caller.nuxway.services`,
                  label: "Access",
                }}
              />
            </RotatingCard>
          </Grid>
          {/* Segundo Item */}
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard locked>
              <RotatingCardFront
                image={bgFront}
                icon={<ForwardToInboxIcon />}
                title={<>NuxSMS</>}
                description="Software for automatic send of SMS in bulk."
                locked
              />
              <RotatingCardBack
                image={bgBack}
                title="Send Messages"
                description="Make a Custom Message for all you clients!"
                action={{
                  type: "internal",
                  route: "/",
                  label: "Access",
                }}
                locked
              />
            </RotatingCard>
          </Grid>
          {/* Tercer item */}
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard locked>
              <RotatingCardFront
                image={bgFront}
                icon={<DashboardIcon />}
                title={<>NuxCenter</>}
                description="Report generation and real-time monitoring service for Call Centers!"
                locked
              />
              <RotatingCardBack
                image={bgBack}
                title="Supervise and Monitor"
                description="Know the status of your Call Center with historical reports and real-time monitoring."
                action={{
                  type: "internal",
                  route: "/",
                  label: "Access",
                }}
                locked
              />
            </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
